import { Modal, Typography, Box } from "@mui/material";
import { ModalInner } from "./ModalInner";
import MainButton from "components/buttons/MainButton";

const AlertModal = ({ open, close, text, title }) => {
    return (
        <Modal open={open} onClose={close} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description">
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <ModalInner>
                    {title && <h3>{title}</h3>}
                    <p style={{ fontSize: "18px", fontWeight: "500", marginBottom: "30px", textAlign: "center" }}>{text}</p>
                    <div style={{ textAlign: "center" }}>
                        <MainButton text="확인" size="xs" onClick={close} />
                    </div>
                </ModalInner>
            </Box>
        </Modal>
    );
};

export default AlertModal;
