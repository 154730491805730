import React, {useEffect} from "react";
import { useLocation, useRoutes } from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from "react-router";

// routes
import AuthRoutes from "./authRoutes";
import MainRoutes from "./mainRoutes";
import {storeUser} from "store/authSlice";

// ==============================|| ROUTING RENDER ||============================== //

const loggedUserRoutes = ["/project", "/mypage", "/address", "/order", "/progress", "/news", "/faq"];

export default function ThemeRoutes() {
    const location = useLocation();
    const navigate = useNavigate();
    const user = useSelector((state) => state.authentication.user);
    const routes = useRoutes([...MainRoutes, ...AuthRoutes]);
    const dispatch = useDispatch();

    const parseJwt = (token) => {
        let base64Url = token.split('.')[1];
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        let jsonPayload = decodeURIComponent(
            window
                .atob(base64)
                .split('')
                .map(function (c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join('')
        );

        return JSON.parse(jsonPayload);
    };


    if(loggedUserRoutes.some(path => location.pathname.startsWith(path))){
        if (user) {
            let expDate = parseJwt(user)?.exp;
            if (expDate * 1000 < Date.now()) {
                alert('로그인 세션이 만료되었습니다.');
                dispatch(storeUser(null));
                localStorage.removeItem('user');
                navigate('/login');
            }
        }

        if(!user){
            navigate('/login');
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <div style={{minHeight: '75vh'}}>
            {routes}
        </div>
    );
}
