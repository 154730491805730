import React, { lazy } from 'react';
import Loadable from 'components/Loadable';
import { ProjectApi } from 'api/project/index';

import ProjectRequestPage from './request';
const ProjectListPage = Loadable(lazy(() => import('./list')));

const ProjectConfig = {
    routes: [
        {
            path: '',
            children: [
                {
                    path: 'request',
                    element: <ProjectRequestPage />
                },
                {
                    path: 'request/:code',
                    element: <ProjectRequestPage />
                }
            ]
        }
    ],
    API: ProjectApi
};

export default ProjectConfig;
