import React, { useState, lazy } from "react";
import Loader from "components/Loader";
import Routes from "routes";
import { SERVER_URL } from "config";
import { useLocation } from "react-router";
import Loadable from "components/Loadable";

import { useGetMenusQuery, useGetSiteInfoQuery } from "api/main/main";

import { ThemeProvider } from "@mui/material/styles";
import { StyleSheetManager } from "styled-components";
import isPropValid from "@emotion/is-prop-valid";
import ScrollToTop from "utils/scrolltoTop";
import i18n from "i18n";
import LocaleContext from "LocaleContext";

import themes from "themes";
import SideMenu from "views/layout/sideMenu";

import "./assets/css/reset.css";
import "./assets/css/main.css";
import "./assets/css/sub.css";
import "./assets/css/common.css";
import "./assets/css/media.css";
import "./assets/css/mypage.css";

const Header = Loadable(lazy(() => import("views/layout/header")));
const MobileMenu = Loadable(lazy(() => import("views/layout/mobileMenu")));
const Footer = Loadable(lazy(() => import("views/layout/footer")));

const sideMenu = ["/mypage", "/address", "/order", "/progress", "/news", "/faq"];
let hasSideMenu = true;

function App() {
    const location = useLocation();
    const [locale, setLocale] = useState(i18n.language);
    const { data: siteInfo, loading } = useGetSiteInfoQuery();
    const { data: menuData, menuLoading } = useGetMenusQuery();
    const regex = /\/(\d+)$/;

    let path = location.pathname.replace(regex, "");
    if (sideMenu.includes(path)) {
        hasSideMenu = true;
    } else {
        hasSideMenu = false;
    }

    const screenWidth = window.innerWidth;

    return (
        <LocaleContext.Provider value={{ locale, setLocale }}>
            <StyleSheetManager shouldForwardProp={isPropValid} disableVendorPrefixes={false}>
                <ThemeProvider theme={themes()}>
                    {(menuLoading || loading) && <Loader />}
                    <Header menuData={menuData} menuLoading={menuLoading} siteInfo={siteInfo?.content || null} />
                    {/* {location.pathname != "/" && menuData ? <SubMenu menuData={menuData} location={location} /> : ""} */}
                    <div className="contents">
                        <ScrollToTop>
                            {hasSideMenu ? (
                                <div className="side_menu_container">
                                    {screenWidth > 600 ? (
                                        <div className="left">
                                            <SideMenu />
                                        </div>
                                    ) : ''}
                                    <div className="side_menu_detail">
                                        {screenWidth < 600 ? (
                                            <MobileMenu />
                                        ) : ''}
                                        <Routes />
                                    </div>
                                </div>
                            ) : (
                                <Routes />
                            )}
                        </ScrollToTop>
                    </div>
                    {siteInfo?.content && <Footer data={siteInfo?.content} />}
                </ThemeProvider>
            </StyleSheetManager>
        </LocaleContext.Provider>
    );
}

export default App;
