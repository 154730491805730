import React from "react";
import { Avatar } from "@mui/material";
import { NavLink } from "react-router-dom";
import { useGetUserInfoQuery } from "api/auth/auth";
import { SERVER_URL } from "config";

const SideMenu = () => {
    const { data, isLoading } = useGetUserInfoQuery();

    return (
        <div className="side_menu">
            <div className="top">
                <div className="top_avatar">
                    {data?.content?.imageAtch.length > 0 ? (
                        <img
                            src={SERVER_URL + data?.content?.imageAtch[0].url}
                            alt=""
                            style={{ height: "100px", width: "100px", borderRadius: "50%" }}
                        />
                    ) : (
                        <Avatar sx={{ height: "100px", width: "100px" }} />
                    )}
                </div>
                <div className="top_info">
                    <span>{data && data?.content?.memberTypeCd == "MT_01" ? "개인회원" : "기업회원"}</span>
                    <p>{data?.content?.fullname || ""} 님</p>
                </div>
            </div>
            <nav>
                <h5>계정 설정</h5>
                <ul className="acc_sett">
                    <li>
                        <NavLink to="/mypage" activeclassname="active">
                            계정
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/address" activeclassname="active">
                            배송지
                        </NavLink>
                    </li>
                </ul>
                <h5>기업 설정</h5>
                <ul className="com_sett">
                    <li>
                        <NavLink to="/order" activeclassname="active">
                            발주내역
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/progress" activeclassname="active">
                            진행현황
                        </NavLink>
                    </li>
                </ul>
                <h5>기타</h5>
                <ul className="oth">
                    <li>
                        <NavLink to="/news" activeclassname="active">
                            필메이커스소식
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/faq" activeclassname="active">
                            자주하는질문
                        </NavLink>
                    </li>
                </ul>
            </nav>
        </div>
    );
};
export default SideMenu;
