import React from "react";
import {Grid} from "@mui/material";

const ProjectTab = ({text, currStep, step, setStep, active, projectStep}) => {
    return (
        <Grid item xs={12} display={'flex'} alignItems={'center'} sx={{marginTop: '13px', cursor: 'pointer', lineHeight: '34px'}}
              onClick={() => {
                  if(projectStep >= step){
                      setStep(step);
                  }
              }}
        >
            <div className={`circle_num ${active ? "active" : ""} ${currStep > step ? 'done' : ''}`}>{step}</div>
            <div style={{padding: '0 10px', fontSize: '16px', maxWidth: '150px'}}>{text}</div>
        </Grid>
    );
};
export default ProjectTab;
