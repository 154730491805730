import React, { createContext, useContext, useState } from "react";

const PortfolioContext = createContext({
    page: 0, size: 5, searchWrd: "" 
});

export const PortfolioProvider = ({ children }) => {
    const [options, setOptions] = useState({
        page: 0, size: 9, searchWrd: ""
    });

    return <PortfolioContext.Provider value={{ options, setOptions }}>{children}</PortfolioContext.Provider>;
};

export const usePortfolioContext = () => useContext(PortfolioContext);

