import { MainConfig, AuthConfig, CommunityConfig, ProjectConfig } from "views/pages";
import { CommonCodeFilterApi } from "api/commonCode";
import { BannerApi } from "api/main/banner";
import { UserAddressApi } from "api/userAddress/index";

// ==============================|| COMBINE REDUCER ||============================== //

const Reducer = {
    [MainConfig.API.reducerPath]: MainConfig.API.reducer,
    [AuthConfig.API.reducerPath]: AuthConfig.API.reducer,
    [CommunityConfig.API.reducerPath]: CommunityConfig.API.reducer,
    [CommonCodeFilterApi.reducerPath]: CommonCodeFilterApi.reducer,
    [BannerApi.reducerPath]: BannerApi.reducer,
    [UserAddressApi.reducerPath]: UserAddressApi.reducer,
    [ProjectConfig.API.reducerPath]: ProjectConfig.API.reducer,
};

export default Reducer;
