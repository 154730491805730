import brand01 from "assets/images/sub/brand01.png";
import brand02 from "assets/images/sub/brand02.png";
import brand04 from "assets/images/sub/brand04.png";
import brand05 from "assets/images/sub/brand05.png";
import icon_brand01 from "assets/images/sub/ico_brand01.png";
import icon_brand02 from "assets/images/sub/ico_brand02.png";
import icon_brand03 from "assets/images/sub/ico_brand03.png";
import icon_brand04 from "assets/images/sub/ico_brand04.png";
import { Link } from "react-router-dom";

const Pillmakers = () => {
    const brand01_tit = `건기식 제조 No.1 필메이커스에요!`;
    const brand01_desc = `건강기능식품을 제조하는데 어려움이 있으셨나요?\n 누구나 편리하게 건강기능식품 제조를 할 수 있는 필메이커스가 탄생했어요.\n필메이커스 만의 기술력과 전문가가 제품의 원료부터 패키지, 디자인까지 한번에 도와드립니다!\n 내가 원하는 원료, 내가 원하는 디자인, 내가 원하는 제형까지 더 쉽고 빠르게 제작해 보세요.`;
    const brand02_desc = `필메이커스만의 기술력으로 건기식 제작이 더 쉬워졌어요.\n 무료 건기식 에디터를 통해 10분 안에 손쉽게 내가 원하는\n건기식 성분 배합과 디자인, 패키지까지 요청할 수 있으며\n건기식 전문 상담원이 빠르게 견적을 알려줍니다.`;

    const brand03 = [
        {
            icon: icon_brand01,
            tit: "학술팀",
            desc: "효과가 좋고, 안전한 제품을 만들기 위해 필메이 커스의 식품영양 전문가가 전문 학술 정보를 통해 더 퀄리티 높은 상담을 제공하기 위해 데이터베이스화합니다."
        },
        {
            icon: icon_brand02,
            tit: "개발팀",
            desc: "필메이커스 서비스와 연결된 서비스들의 핵심 기능들을 개발하고 고객의 불편을 개선하며 가치를 느낄 수 있는 서비스를 제공합니다."
        },
        {
            icon: icon_brand03,
            tit: "그로스팀",
            desc: "서비스를 기획하여, 비즈니스 성장을 위한 제품 성장 가속화와 지표 개선을 위한 데이터 기반 실험을 설계하고 수행합니다."
        },
        {
            icon: icon_brand04,
            tit: "디자인팀",
            desc: "고객님의 패키지 및 디자인을 진행하며, 원하는 서비를 만들기 위해 전문 건기식 디자이너가 제작합니다."
        }
    ];

    return (
        <div className="pillmakers">
            <section className="brand01">
                <div className="container">
                    <h2 className="brand_tit">{brand01_tit}</h2>
                    <p className="brand_desc">{brand01_desc}</p>
                </div>
            </section>
            <section className="brand02">
                <div className="container">
                    <div className="inn">
                        <h2 className="brand_tit">필메이커스 풀필먼트 서비스</h2>
                        <article>
                            <div className="img">
                                <img src={brand02} alt="필메이커스 풀필먼트 서비스" />
                            </div>
                            <div className="txt">
                                <h4>누구나 빠르고 쉽게, 건기식 제작</h4>
                                <p>{brand02_desc}</p>
                                <Link to="/project/request" className="btn">
                                    시작하기
                                </Link>
                            </div>
                        </article>
                    </div>
                </div>
            </section>
            <section className="brand04">
                <div className="container">
                    <div className="inn">
                        <h2 className="brand_tit">필메이커스 풀필먼트 서비스</h2>
                        <article>
                            <div className="img">
                                <img src={brand04} alt="필메이커스 풀필먼트 서비스" />
                            </div>
                            <div className="txt">
                                건강기능식품 제조를 할 때, 디자인까지 업체를 찾으려고 하니 <br /> 시간이 많이 걸리고 오래 걸렸어요.
                                필메이커스를 이용하니 하나하나 <br /> 신경 쓰지 않아도 되고 너무 간편해요.
                            </div>
                        </article>
                    </div>
                </div>
            </section>
            <section className="brand05">
                <div className="container">
                    <div className="inn">
                        <article>
                            <div className="txt">
                                건기식 창업을 하려고 해서 문의를 많이 넣어봤었는데
                                <br />
                                최소 MOQ가 높아서 부담이 됐었는데 필메이커스는 최소 MOQ
                                <br />
                                단위가 작아서 부담 없이 출시를 할 수 있었어요.
                            </div>
                            <div className="img">
                                <img src={brand05} alt="필메이커스 풀필먼트 서비스" />
                            </div>
                        </article>
                    </div>
                </div>
            </section>
            <section className="brand03">
                <div className="container">
                    <div className="inn">
                        <h2 className="brand_tit">건기식의 전문가가 도와드립니다!</h2>
                        <div className="content">
                            {brand03.map((item, i) => (
                                <div className="item" key={i}>
                                    <h4>
                                        <img src={item.icon} alt="건기식의 전문가가 도와드립니다!" />
                                        <span>{item.tit}</span>
                                    </h4>
                                    <p>{item.desc}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};
export default Pillmakers;
