import React, { createContext, useContext, useState } from "react";

const ProgressContext = createContext({
    page: 0, size: 5, searchWrd: "", orderYn: 'Y'
});

export const ProgressProvider = ({ children }) => {
    const [options, setOptions] = useState({
        page: 0, size: 5, searchWrd: "", orderYn: 'Y'
    });

    return <ProgressContext.Provider value={{ options, setOptions }}>{children}</ProgressContext.Provider>;
};

export const useProgressContext = () => useContext(ProgressContext);

