import { createApi } from "@reduxjs/toolkit/query/react";
import { Api } from "../index";
import { URL } from "store/urls";

export const ProjectApi = createApi({
    reducerPath: "ProjectApi",
    baseQuery: Api.fetchBaseQuery,
    tagTypes: ["Project"],
    endpoints: (build) => ({
        getProjects: build.query({
            query: (params) => `${URL.PROJECT_LIST}?${params}`,
            providesTags: ["PROJECT_LIST"]
        }),
        getMainIngredients: build.query({
            query: (params) => `${URL.INGREDIENT_MAIN_LIST}?${params}`,
            providesTags: ["INGREDIENT_MAIN_LIST"]
        }),
        getSecondaryIngredients: build.query({
            query: (params) => `${URL.INGREDIENT_SECONDARY_LIST}?${params}`,
            providesTags: ["INGREDIENT_SECONDARY_LIST"]
        }),
        getProject: build.query({
            query: (params) => `${URL.PROJECT_DETAIL}/${params}`,
            providesTags: ["PROJECT_DETAIL"]
        }),
        getProjectFormulations: build.query({
            query: () => `${URL.FORMULATION_LIST}`,
            providesTags: ["FORMULATION_LIST"]
        }),
        getProjectPackages: build.query({
            query: () => `${URL.PACKAGE_LIST}`,
            providesTags: ["PACKAGE_LIST"]
        }),
        getProjectConcepts: build.query({
            query: () => `${URL.CONCEPT_LIST}`,
            providesTags: ["CONCEPT_LIST"]
        }),
        getPillType: build.query({
            query: (params) => `${URL.PILL_TYPE}/${params}`,
            providesTags: ["PILL_TYPE"]
        }),
        createProject: build.mutation({
            query: (body) => ({
                url: `${URL.PROJECT_CREATE}`,
                method: "POST",
                body: body
            })
        }),
        updateProject: build.mutation({
            query: (body) => ({
                url: `${URL.PROJECT_UPDATE}`,
                method: "POST",
                body: body
            }),
            invalidatesTags: ['PROJECT_LIST','PROJECT_DETAIL']
        }),
        duplicateProject: build.mutation({
            query: (body) => ({
                url: `${URL.PROJECT_DUPLICATE}`,
                method: "POST",
                body: body
            }),
            invalidatesTags: ['PROJECT_LIST','PROJECT_DETAIL']
        }),
        registerProjectIngredients: build.mutation({
            query: (body) => ({
                url: `${URL.PROJECT_INGREDIENTS_REGISTER}`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['PROJECT_LIST','PROJECT_DETAIL']
        }),
        registerProjectSecondaryIngredients: build.mutation({
            query: (body) => ({
                url: `${URL.PROJECT_SECONDARY_INGREDIENTS_REGISTER}`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['PROJECT_LIST','PROJECT_DETAIL']
        }),
        deleteProject: build.mutation({
            query: (body) => ({
                url: `${URL.PROJECT_DELETE}`,
                method: "POST",
                body: body
            }),
            invalidatesTags: ['PROJECT_LIST','PROJECT_DETAIL']
        }),
    })
});

export const {
    useGetProjectsQuery,
    useGetMainIngredientsQuery,
    useGetSecondaryIngredientsQuery,
    useGetProjectQuery,
    useGetPillTypeQuery,
    useGetProjectFormulationsQuery,
    useGetProjectPackagesQuery,
    useGetProjectConceptsQuery,
    useCreateProjectMutation,
    useUpdateProjectMutation,
    useDuplicateProjectMutation,
    useRegisterProjectIngredientsMutation,
    useRegisterProjectSecondaryIngredientsMutation,
    useDeleteProjectMutation,
} = ProjectApi;
