import React, { lazy } from "react";
import Loadable from "components/Loadable";
import ProjectConfig from "views/pages/project";
import MyPageConfig from "views/pages/mypage";

const MyPage = Loadable(lazy(() => import("views/pages/mypage/myPage")));

const AuthRoutes = [
    { path: "/", children: [...MyPageConfig.routes] },
    { path: "/project", children: [...ProjectConfig.routes] }
];

export default AuthRoutes;
