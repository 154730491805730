import React from "react";
import { styled } from "@mui/system";
import Button from "./Button";
import { CircularProgress } from "@mui/material";

// Style
import { ButtonStyle } from "./style";

const BaseButton = styled(Button)({});

const MainButton = ({ type, size, text, loading, onClick, ...props }) => {
    return (
        <>
            <BaseButton
                type={type || "button"}
                onClick={onClick}
                disabled={loading}
                startIcon={loading ? <CircularProgress size={20} color="light" /> : ""}
                {...props}
                size={size}
                sx={ButtonStyle.mainButton}
            >
                {text}
            </BaseButton>
        </>
    );
};
export default MainButton;
