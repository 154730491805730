import { useState, createContext } from 'react';

export const ValidateContext = createContext({
    error: {},
    setError: () => {}
});

const FormWrapper = ({ children }) => {
    let [error, setError] = useState({});
    return <ValidateContext.Provider value={{ error, setError }}>{children}</ValidateContext.Provider>;
};

export default FormWrapper;
