import React, { lazy } from 'react';
import Loadable from 'components/Loadable';
import { AuthApi } from 'api/auth/auth';
import { ProgressProvider } from "./progressContext";
import { NewProvider } from "./newsContext";
import { OrderProvider } from "./orderList/orderContext";
const MyPage = Loadable(lazy(() => import('./myPage')));
const Address = Loadable(lazy(() => import('./address')));
const OrderList = Loadable(lazy(() => import('./orderList/list')));
const OrderDetail = Loadable(lazy(() => import('./orderList/projectDetail')));
const Progress = Loadable(lazy(() => import('./progress')));
const ProgressDetail = Loadable(lazy(() => import('./progressDetail')));
const Faq = Loadable(lazy(() => import('./faqList')));
const NewsList = Loadable(lazy(() => import('./newList')));
const NewsDetail = Loadable(lazy(() => import('./newsDetail')));

const MyPageConfig = {
    routes: [
        {
            path: '',
            children: [
                {
                    path: 'mypage',
                    element: <MyPage />
                },
                {
                    path: 'address',
                    element: <Address />
                },
                {
                    path: 'order',
                    element: <OrderProvider><OrderList /></OrderProvider>
                },
                {
                    path: 'order/:code',
                    element: <OrderProvider><OrderDetail /></OrderProvider>
                },
                {
                    path: 'progress',
                    element: <ProgressProvider><Progress /></ProgressProvider>
                },
                {
                    path: 'progress/:code',
                    element: <ProgressProvider><ProgressDetail /></ProgressProvider>
                },
                {
                    path: 'news',
                    element: <NewProvider><NewsList /></NewProvider>
                },
                {
                    path: 'news/:code',
                    element: <NewProvider><NewsDetail /></NewProvider>
                },
                {
                    path: 'faq',
                    element: <Faq />
                }
            ]
        }
    ],
    API: AuthApi
};

export default MyPageConfig;
