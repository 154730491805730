import { styled } from "@mui/system";
import { Button as BaseButton } from "@mui/material";
import React from "react";

const StyledButton = styled(BaseButton)({
    backgroundColor: "#fff",
    borderRadius: "6px",
    color: "#503795",
    borderColor: "#503795",
    "&:hover": {
        background: "#503795",
        color: "#fff",
        borderColor: "#fff"
    }
});

const Button = ({ size, ...props }) => {
    const sizes = {
        sm: { width: "45px", height: "34px", fontSize: "13px" },
        xs: { width: "80", height: "40px", fontSize: "14px" },
        md: { width: "100px", height: "40px" },
        lg: { width: "", height: "" },
        xl: { width: "170px", height: "30px" },
        full: { width: "100%", height: "45px", fontSize: "14px" }
    };

    const sx = { width: "100px", height: "40px" };

    if (sizes[size]) {
        sx.width = sizes[size].width;
        sx.height = sizes[size].height;
        sx.fontSize = sizes[size].fontSize;
    }

    return <StyledButton color="primary" variant="outlined" sx={sx} disableElevation {...props} />;
};

export default Button;
