import React, { useEffect, useContext } from "react";
import { Avatar, Button, Grid, Typography, FormHelperText } from "@mui/material";
import { Box, height } from "@mui/system";
import { FileUploadButton } from "components/buttons/FileUploadButton";
import { ValidateContext } from "components/form/ValidateContext";

import { useState } from "react";
import { inputStyle } from "./style";
import AnimateButton from "components/extended/AnimateButton";
import { CustomModal } from "components/modal";
import { SERVER_URL } from "config";
import ProjectButton from "components/buttons/ProjectButton";

export const FileUploader = ({ onChange, inputProps, attachments, isImage, deleteFile, disabled, size, maxLength, ...props }) => {
    const [files, setFiles] = useState([]);
    const [alertText, setAlertText] = useState("");
    const [alertModalOpen, setAlertModalOpen] = useState(false);
    const { error } = useContext(ValidateContext);

    const handleFileUpload = (event) => {
        const fileList = event.target.files;
        const newFiles = [...files];

        if (inputProps?.allows && fileList) {
            if (fileList[0]?.name.split(".").pop() != inputProps.allows) {
                setAlertText("확장자가 ‘" + inputProps.allows.toLocaleUpperCase() + "’인 파일만 첨부 가능합니다.");
                setAlertModalOpen(true);
                return;
            }
        }
        if (inputProps?.maxuploadsize && fileList[0]?.size / (1024 * 1024 * 1024) > inputProps?.maxuploadsize) {
            setAlertText("파일은 최대 " + inputProps?.maxuploadsize * 1000 + "MB 개까지만 업로드할 수 있습니다.");
            setAlertModalOpen(true);
            return;
        }
        if (files?.filter((file) => file.imageName == fileList[0].name).length > 0) {
            setAlertText("같은 파일을 업로드할 수 없습니다.");
            setAlertModalOpen(true);
            event.target.value = null;
            return;
        }

        for (let i = 0; i < fileList.length; i++) {
            const file = fileList[i];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                newFiles.push({
                    image: reader.result,
                    imageName: file.name,
                    filesize: `${(file.size / (1024 * 1024)).toFixed(2)} MB`,
                    extension: file.name.split(".").pop()
                });

                if (newFiles.length + fileList.length > maxLength) {
                    setAlertText("파일은 최대 " + (maxLength - 1) + " 개까지만 업로드할 수 있습니다.");
                    setAlertModalOpen(true);
                    return;
                }
                setFiles(newFiles.slice(0, maxLength));

                event.target.value = null;
            };
        }

        onChange(fileList);
    };

    useEffect(() => {
        if (attachments && attachments.length > 0) {
            let temp = attachments.map((item) => {
                return {
                    seq: item.fileSeq,
                    imageName: item.fileNm,
                    image: SERVER_URL + item.url,
                    filesize: `${(item.fileSize / (1024 * 1024)).toFixed(2)} MB`,
                    extension: item.fileExt
                };
            });

            setFiles(temp);
        } else {
            setFiles([]);
        }
    }, [attachments]);

    const handleDeleteImage = (fileName, seq) => {
        const filteredFiles = files?.filter((file) => file.imageName !== fileName);
        setFiles(filteredFiles);

        deleteFile(fileName, seq);
    };

    const handleAlertModalClose = () => {
        setAlertModalOpen(false);
    };

    return (
        <>
            <Grid container gap={1} alignItems="center">
                <Grid item>
                    <Box sx={{ background: "transparent" }} position={"relative"}>
                        <FileUploadButton disabled={disabled} inputProps={inputProps} onChange={handleFileUpload} size={size} multiple />
                    </Box>
                </Grid>
                <Grid
                    item
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px"
                    }}
                >
                    {files.length > 0 ? (
                        files.map((image, index) => (
                            <Grid
                                container
                                key={index}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    flexWrap: "nowrap",
                                    gap: "10px"
                                }}
                            >
                                {isImage ? (
                                    <Grid item sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                        <Box
                                            sx={{
                                                border: "1px solid #C6C6C6",
                                                background: "#fff",
                                                borderRadius: "0px",
                                                minWidth: "30px",
                                                height: "100%",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                marginRight: "10px"
                                            }}
                                        >
                                            <img
                                                src={files[0].image}
                                                alt="UploadedImage"
                                                style={{ height: "auto", width: "240px", borderRadius: "0px" }}
                                            />
                                        </Box>
                                    </Grid>
                                ) : (
                                    <Grid item sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
                                        <Box
                                            sx={{
                                                border: "2px solid #00C3F9",
                                                background: "#E0F8FE",
                                                borderRadius: "50%",
                                                minWidth: "30px",
                                                height: "30px",
                                                fontSize: "10px",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                marginRight: "10px"
                                            }}
                                        >
                                            {image.extension}
                                        </Box>
                                        <Box sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                                            <Typography>{image.imageName}</Typography>
                                            <Typography>{image.filesize}</Typography>
                                        </Box>
                                    </Grid>
                                )}

                                <Grid item>
                                    <Avatar
                                        style={inputStyle.imgUploadAvatar}
                                        sx={{ cursor: "pointer" }}
                                        // src={require("assets/images/icon/deleteRedButton.svg").default}
                                        onClick={() => handleDeleteImage(image.imageName, image.seq)}
                                    />
                                </Grid>
                            </Grid>
                        ))
                    ) : (
                        <p>업로드 된 파일이 없습니다.</p>
                    )}
                </Grid>
            </Grid>

            <CustomModal
                open={alertModalOpen}
                onClose={handleAlertModalClose}
                width={{ xs: "85%", sm: "50%", md: 319 }}
                sx={{ padding: {md: "40px", xs: "20px"} }}
            >
                <Typography variant={"h2"} sx={{ fontWeight: "bold", textAlign: "center", color: "#000000" }}>
                    안내
                </Typography>
                <Typography variant={"h4"} sx={{ margin: "30px 0", textAlign: "center" }}>
                    {alertText}
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <AnimateButton>
                        <ProjectButton
                            onClick={handleAlertModalClose}
                            text={"확인"}
                            sx={{
                                width: "100px",
                                borderRadius: "5px",
                                height: "40px"
                            }}
                        />
                    </AnimateButton>
                </Box>
            </CustomModal>
        </>
    );
};

export default FileUploader;
