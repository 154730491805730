import React, { lazy } from "react";
import Pages from "store/pages";
import Loadable from "components/Loadable";
import { CommunityApi } from "api/community/community";
import { NewProvider } from "./news/context";
import { EventProvider } from "./event/context";
import { PortfolioProvider } from "./portfolio/context";
import {ConsultationPage} from "./consultation";

const NewsPage = Loadable(lazy(() => import("./news/index")));
const NewsDetailPage = Loadable(lazy(() => import("./news/detail")));
const EventPage = Loadable(lazy(() => import("./event/index")));
const EventDetailPage = Loadable(lazy(() => import("./event/detail")));
const PortfolioPage = Loadable(lazy(() => import("./portfolio/index")));
const PortfolioDetailPage = Loadable(lazy(() => import("./portfolio/detail")));

const CommunityConfig = {
    routes: [
        { path: Pages.COMMUNITY_NEWS, element: <NewProvider><NewsPage /></NewProvider> },
        { path: Pages.COMMUNITY_NEWS_DETAIL, element: <NewProvider><NewsDetailPage /></NewProvider> },
        { path: Pages.COMMUNITY_EVENT, element: <EventProvider><EventPage /></EventProvider>},
        { path: Pages.COMMUNITY_EVENT_DETAIL, element: <EventProvider><EventDetailPage /></EventProvider>},
        { path: Pages.COMMUNITY_PORTFOLIO, element: <PortfolioProvider><PortfolioPage /></PortfolioProvider>},
        { path: Pages.COMMUNITY_PORTFOLIO_DETAIL, element: <PortfolioProvider><PortfolioDetailPage /></PortfolioProvider>},
        { path: Pages.COMMUNITY_CONSULTATION, element: <ConsultationPage /> },
    ],
    API: CommunityApi
};

export default CommunityConfig;
