import React, { forwardRef, useEffect, useState } from "react";
import { Box, FormHelperText, OutlinedInput, Skeleton, Typography } from "@mui/material";
import { addComma } from "utils/functions/common";

const TextInputField = forwardRef(
    ({ sx, isLoading, placeholder, onChange, onBlur, error, width, height, size, label, type, disabled, ...props }, ref) => {
        const [val, setVal] = useState("");

        useEffect(() => {
            if (type === "number") {
                const formattedValue = addComma(props.value);
                setVal(formattedValue);
            } else {
                setVal(props.value || "");
            }
        }, [props.value, type]);

        const handleChange = (e) => {
            const { value } = e.target;
            let formattedValue = value;

            if (type === "number") {
                formattedValue = value.replace(/\D/g, "");
                formattedValue = addComma(formattedValue);
            }

            setVal(formattedValue);
            if (onChange) {
                onChange({ ...e, target: { ...e.target, value: formattedValue } });
            }
        };

        return isLoading ? (
            <Skeleton animation="wave" width={width || "200px"} height={height || "25px"} />
        ) : (
            <Box width={"100%"} textAlign={"start"} position={"relative"}>
                {label && <Typography>{label}</Typography>}
                <OutlinedInput
                    ref={ref}
                    error={props?.erros}
                    onChange={handleChange}
                    value={val || val}
                    size={size || "small"}
                    placeholder={placeholder || ""}
                    onBlur={onBlur}
                    sx={{ width: { md: width ? width : "100%", xs: "auto" }, ...sx }}
                    inputProps={{
                        maxLength: props?.maxLength || 255
                    }}
                    disabled={disabled}
                    {...props}
                />
                <FormHelperText error sx={{ position: "absolute", bottom: "-20px", fontSize: '11px' }}>{error}</FormHelperText>
            </Box>
        );
    }
);

export default TextInputField;
