/**
 * Typography used in theme
 * @param {JsonObject} theme theme customization object
 */

export default function themeTypography(theme) {
    return {
        fontFamily: `'Pretendard', sans-serif`,
        h6: {
            fontWeight: 500,
            color: theme.heading,
            fontSize: "0.55rem",
            "@media (min-width:600px)": {
                fontSize: "0.65rem"
            },
            "@media (min-width:900px)": {
                fontSize: "0.75rem"
            }
        },
        h5: {
            fontWeight: 500,
            color: theme.heading,
            fontSize: "0.675rem",
            "@media (min-width:600px)": {
                fontSize: "0.775rem"
            },
            "@media (min-width:900px)": {
                fontSize: "0.875rem"
            }
        },
        h4: {
            color: theme.heading,
            fontWeight: 400,
            fontSize: "1rem",
            "@media (min-width:700px)": {
                fontSize: "0.9rem"
            },
            "@media (min-width:900px)": {
                fontSize: "1rem"
            }
        },
        h3: {
            color: theme.heading,
            fontWeight: "bold",
            fontSize: "1rem",
            "@media (min-width:600px)": {
                fontSize: "1.25rem"
            },
            "@media (min-width:900px)": {
                fontSize: "1.375rem"
            }
        },
        h2: {
            color: theme.heading,
            fontWeight: 700,
            fontSize: "1.3rem",
            "@media (min-width:600px)": {
                fontSize: "1.4rem"
            },
            "@media (min-width:900px)": {
                fontSize: "1.5rem"
            }
        },
        h1: {
            color: theme.heading,
            fontWeight: 700,
            fontSize: "1.9rem",
            "@media (min-width:600px)": {
                fontSize: "2rem"
            },
            "@media (min-width:900px)": {
                fontSize: "2.375rem"
            }
        },
        subtitle1: {
            fontWeight: 500,
            color: theme.textDark,
            fontSize: "0.625rem",
            "@media (min-width:600px)": {
                fontSize: "0.75rem"
            },
            "@media (min-width:900px)": {
                fontSize: "0.875rem"
            }
        },
        subtitle2: {
            fontWeight: 500,
            color: theme.textDark,
            fontSize: "1.5rem",
            "@media (max-width:600px)": {
                fontSize: "1rem"
            }
        },
        subdate: {
            fontWeight: 300,
            color: theme.darkTextSecondary,
            fontSize: "1rem",
            "@media (max-width:600px)": {
                fontSize: "0.8rem"
            }
        },
        caption: {
            color: theme.darkTextSecondary,
            fontWeight: 400,
            fontSize: "0.5rem",
            "@media (min-width:600px)": {
                fontSize: "0.625rem"
            },
            "@media (min-width:900px)": {
                fontSize: "0.75rem"
            }
        },
        body1: {
            fontWeight: 400,
            lineHeight: "1.334em",
            fontSize: "0.65rem",
            "@media (min-width:600px)": {
                fontSize: "0.75rem"
            },
            "@media (min-width:900px)": {
                fontSize: "0.875rem"
            }
        },
        button: {
            textTransform: "capitalize"
        },
        logo: {
            textTransform: "uppercase",
            color: theme.heading,
            fontWeight: 800,
            fontSize: "1.3rem",
            "@media (min-width:600px)": {
                fontSize: "1.5rem",
                letterSpacing: "-1px"
            },
            "@media (min-width:900px)": {
                fontSize: "1.625rem",
                letterSpacing: "normal"
            }
        },
        menu_text: {
            textTransform: "uppercase",
            color: theme.heading,
            fontWeight: 500,
            fontSize: "0.80rem",
            "@media (min-width:600px)": {
                fontSize: "0.9rem",
                letterSpacing: "-2px"
            },
            "@media (min-width:900px)": {
                fontSize: "1.0625rem",
                letterSpacing: "normal"
            }
        },
        table_header: {
            textTransform: "uppercase",
            color: theme.heading,
            fontWeight: 800,
            fontSize: "1.475rem",
            "@media (min-width:600px)": {
                fontSize: "1.675rem"
            },
            "@media (min-width:900px)": {
                fontSize: "1.875rem",
                letterSpacing: "normal"
            }
        },
        table_data_text: {
            textTransform: "uppercase",
            width: "100%",
            color: theme.heading,
            fontWeight: 400,
            fontSize: "0.675rem",
            "@media (min-width:600px)": {
                fontSize: "0.775rem"
            },
            "@media (min-width:900px)": {
                fontSize: "0.875rem",
                letterSpacing: "normal"
            }
        },
        ttl2: {
            color: "#ffffff",
            fontWeight: 100,
            lineHeight: "32px",
            fontSize: "1.5rem",
            "@media (min-width:600px)": {
                fontSize: "1.9rem",
                lineHeight: "32px"
            },
            "@media (min-width:900px)": {
                fontSize: "2.375rem",
                lineHeight: "58px"
            }
        },
        ttl1: {
            color: "#ffffff",
            fontWeight: 500,
            lineHeight: "32px",
            fontSize: "1.9rem",
            letterSpacing: "-1px",
            "@media (min-width:600px)": {
                fontSize: "2.3rem",
                lineHeight: "32px"
            },
            "@media (min-width:900px)": {
                fontSize: "2.625rem",
                lineHeight: "58px"
            }
        },
        chartText: {
            color: "#222222",
            fontWeight: 500,
            textAlign: "center",
            fontSize: "0.9rem",
            letterSpacing: "0",
            "@media (min-width:600px)": {
                fontSize: "1.0rem"
            },
            "@media (min-width:900px)": {
                fontSize: "1.125rem"
            }
        },
        chartTtl: {
            color: "#333333",
            fontWeight: 800,
            fontSize: "1.6rem",
            letterSpacing: "-1px",
            lineHeight: "55px",
            "@media (min-width:600px)": {
                fontSize: "1.8rem"
            },
            "@media (min-width:900px)": {
                fontSize: "2rem"
            }
        },
        iconTtl: {
            color: "#222222",
            fontWeight: 500,
            fontSize: "1rem",
            letterSpacing: "0px",
            "@media (min-width:600px)": {
                fontSize: "1.15rem"
            },
            "@media (min-width:900px)": {
                fontSize: "1.25rem"
            }
        },
        iconNumber: {
            color: "#333333",
            fontWeight: 700,
            fontSize: "1.8rem",
            lineHeight: "64px",
            letterSpacing: "-1px",
            "@media (min-width:600px)": {
                fontSize: "2rem"
            },
            "@media (min-width:900px)": {
                fontSize: "2.25rem"
            }
        },
        comment: {
            color: "#222222",
            fontWeight: 500,
            fontSize: "0.7rem",
            lineHeight: "21px",
            letterSpacing: "0px",
            "@media (min-width:600px)": {
                fontSize: "0.8375rem"
            },
            "@media (min-width:900px)": {
                fontSize: "0.9375rem"
            }
        },
        name: {
            color: theme.heading,
            fontWeight: 500,
            fontSize: "0.7rem",
            "@media (min-width:700px)": {
                fontSize: "0.9rem"
            },
            "@media (min-width:900px)": {
                fontSize: "1rem"
            }
        },
        portfolioTtl1: {
            fontWeight: 700,
            fontSize: "clamp(15.63px, 1vw + 13.12px, 30px)"
        },
        portfolioTtl2: {
            fontWeight: 700,
            fontSize: "clamp(12.5px, 0.77vw + 10.57px, 24px)"
        },
        base: {
            fontWeight: 400,
            fontSize: "clamp(10px, 0.59vw + 8.52px, 18px)",
            lineHeight: "30px",
            letterSpacing: "-0.075em"
        }
    };
}
