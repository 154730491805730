/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import parser from "html-react-parser";

import { Checkbox, Grid, Typography, Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";

import { useGetSiteInfoQuery } from "api/main/main";
import { useContactCreateMutation } from "api/community/community";

import Loader from "components/Loader";
import { CustomModal } from "components/index";
import Button from "components/buttons/Button";
import { CustomTextInput } from "components/useForm";
import SaveButton from "components/buttons/SaveButton";
import CloseButton from "components/buttons/CloseButton";
import AnimateButton from "components/extended/AnimateButton";

import advice01Imgsrc from "assets/images/sub/advice01.png";
import ProjectButton from "components/buttons/ProjectButton";
import { height } from "@mui/system";

const formDefaultValues = {
    username: "",
    companyName: "",
    phone: "",
    email: "",
    siteUrl: "",
    content: "",
    checkbox1: false,
    checkbox2: false
};

const validateSchema = yup.object({
    username: yup.string().required(),
    email: yup.string().required().email(),
    companyName: yup.string().required(),
    phone: yup.string().required().min(11),
    checkbox1: yup.bool().oneOf([true]),
    checkbox2: yup.bool().oneOf([true])
});

export const ConsultationPage = () => {
    const [onAction, resultAction] = useContactCreateMutation();
    const { data: siteInfo } = useGetSiteInfoQuery();
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [alertModalOpen, setAlertModalOpen] = useState(false);
    const [termsModalOpen, setTermsModalOpen] = useState(false);
    const [alertText, setAlertText] = useState("");

    const {
        control,
        handleSubmit,
        setValue,
        reset,
        trigger,
        watch,
        formState: { errors }
    } = useForm({
        mode: "onBlur",
        defaultValues: formDefaultValues,
        resolver: yupResolver(validateSchema)
    });

    const submit = (values) => {
        setConfirmModalOpen(false);
        let formData = new FormData();
        formData.append("username", values.username);
        formData.append("email", values.email);
        formData.append("phone", values.phone);
        formData.append("siteUrl", values.siteUrl);
        formData.append("companyName", values.companyName);
        formData.append("content", values.content);

        onAction(formData)
            .then((res) => {
                if (res.data.status == 200) {
                    setAlertText(
                        <>
                            상담 신청이 성공적으로 접수되었습니다. <br />
                            상담 회신은 등록하신 이메일로 <br />
                            안내드리겠습니다.
                        </>
                    );
                    setAlertModalOpen(true);
                } else {
                    setAlertText(
                        <>
                            죄송합니다, <br />
                            처리 중 예상치 못한 오류가 발생했습니다.
                            <br /> 잠시 후 다시 시도해 주세요.
                        </>
                    );
                    setAlertModalOpen(true);
                }
                reset();
            })
            .catch((err) => console.log("useForgotIdMutation ERROR => ", err));
    };

    const showConfirmModal = async () => {
        try {
            await trigger();

            const formValues = watch();
            const isValid = await validateSchema.isValid(formValues);

            if (isValid) {
                setConfirmModalOpen(true);
            }
        } catch (validationError) {
            console.log("Validation failed:", validationError);
        }
    };

    const handleNumWithDash = (e) => {
        let inputValue = e.target.value;
        inputValue = inputValue.replace(/[^\d]/g, "");
        if (inputValue.startsWith("010")) {
            if (inputValue.length > 3) {
                inputValue = inputValue.replace(/(\d{3})(\d{1,4})/, "$1-$2");
            }
            if (inputValue.length > 7) {
                inputValue = inputValue.replace(/(\d{3}-\d{4})(\d{1,4})/, "$1-$2");
                if (inputValue.length > 13) {
                    inputValue = inputValue.slice(0, 13);
                }
            }
        }
        setValue(e.target.name, inputValue);
    };

    return (
        <div className="advice">
            <div className="container">
                {resultAction.isLoading && <Loader />}
                <div className="title">VIP 상담 요청</div>
                <div className="container1">
                    <Grid container spacing={6}>
                        <Grid item md={7} xs={12} style={{ cursor: "pointer" }}>
                            <div>
                                <p className="subTitle">VIP 상담 요청이 무엇인가요?</p>
                                <div style={{ fontSize: 14, marginBottom: "80px", marginLeft: "8px" }}>
                                    <p>
                                        · 건기식 메이킹으로 성분의 배합, 기획, 디자인 등이 어려우신 경우 필메이커스 전문팀에서 전체적으로
                                        도와드리는 VIP 서비스입니다.
                                    </p>
                                    <p>· VIP 상담 요청 시, 컨셉을 제시해 주시면 가장 적합한 제품을 기획해 드립니다.</p>
                                    <p>· 상품에 대한 출시 후 마케팅, 광고 컨셉 등 모든 부분에 대하여 가이드 해드립니다.</p>
                                </div>
                            </div>
                            <img src={advice01Imgsrc} alt="advice_img_src" style={{ borderRadius: 6, height: 350 }} />
                        </Grid>
                        <Grid item md={5} xs={12}>
                            <div
                                style={{
                                    backgroundColor: "#f8f8f8",
                                    padding: 20,
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 28,
                                    borderRadius: "6px"
                                }}
                            >
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <label htmlFor="username">
                                        성함 <span style={{ color: "#ef2d1f" }}> *</span>
                                    </label>
                                    <CustomTextInput
                                        disabled={resultAction.isLoading}
                                        id="username"
                                        control={control}
                                        name="username"
                                        radius="4px"
                                        margintop="8px"
                                        error={errors?.username}
                                        placeholder="성함을 입력해 주세요"
                                    />
                                </div>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <label htmlFor="companyName">
                                        회사명 <span style={{ color: "#ef2d1f" }}> *</span>
                                    </label>
                                    <CustomTextInput
                                        disabled={resultAction.isLoading}
                                        id="companyName"
                                        control={control}
                                        name="companyName"
                                        radius="4px"
                                        margintop="8px"
                                        error={errors?.companyName}
                                        placeholder="회사명을 입력해 주세요"
                                    />
                                </div>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <label htmlFor="phone">
                                        전화번호 <span style={{ color: "#ef2d1f" }}> *</span>
                                    </label>
                                    <CustomTextInput
                                        disabled={resultAction.isLoading}
                                        id="phone"
                                        control={control}
                                        name="phone"
                                        radius="4px"
                                        margintop="8px"
                                        onKeyUp={handleNumWithDash}
                                        error={errors?.phone}
                                        placeholder="전화번호를 입력해 주세요"
                                    />
                                </div>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <label htmlFor="email">
                                        이메일 <span style={{ color: "#ef2d1f" }}> *</span>
                                    </label>
                                    <CustomTextInput
                                        disabled={resultAction.isLoading}
                                        id="email"
                                        control={control}
                                        name="email"
                                        radius="4px"
                                        margintop="8px"
                                        error={errors?.email}
                                        placeholder="이메일을 입력해 주세요"
                                    />
                                </div>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <label htmlFor="siteUrl">도입예정 사이트</label>
                                    <CustomTextInput
                                        disabled={resultAction.isLoading}
                                        id="siteUrl"
                                        control={control}
                                        name="siteUrl"
                                        radius="4px"
                                        margintop="8px"
                                        error={errors?.siteUrl}
                                        placeholder="URL을 입력해 주세요"
                                    />
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                                    <label htmlFor="content">문의 사항</label>
                                    <Controller
                                        control={control}
                                        name="content"
                                        render={({ field }) => {
                                            const { onChange, value } = field;
                                            return (
                                                <textarea
                                                    disabled={resultAction.isLoading}
                                                    className="consultationTextArea"
                                                    placeholder="문의 내용을 입력해주세요(최대 200자)"
                                                    rows="8"
                                                    id="content"
                                                    name="content"
                                                    onChange={onChange}
                                                    value={value}
                                                    style={{
                                                        resize: "vertical",
                                                        outline: "none",
                                                        border: "1px solid #c2c0c0",
                                                        borderRadius: "4px",
                                                        fontSize: "13px",
                                                        fontWeight: 200,
                                                        font: "inherit",
                                                        letterSpacing: "inherit"
                                                    }}
                                                />
                                            );
                                        }}
                                    ></Controller>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 4,
                                        color: "#8e8ea9",
                                        paddingLeft: 8
                                    }}
                                >
                                    <p>아래 링크를 눌러 내용을 주의 깊게 읽으세요.</p>
                                    <p>체크박스를 선택하면, 다음 항목을 모두 읽고 동의한 것으로 간주합니다.</p>
                                </div>
                                <div style={{ borderTop: "1px solid #e9e9e9", margin: "0px 8px" }}></div>
                                <div>
                                    <Controller
                                        control={control}
                                        name="checkbox1"
                                        render={(fields) => {
                                            const { value, onChange } = fields.field;

                                            return (
                                                <div style={{ display: "flex", color: "#8e8ea9", marginLeft: 10 }}>
                                                    <FormControlLabel
                                                        value="end"
                                                        control={
                                                            <Checkbox
                                                                disabled={resultAction.isLoading}
                                                                sx={{
                                                                    color: errors.checkbox1 ? "red" : ""
                                                                }}
                                                                onChange={onChange}
                                                                checked={value}
                                                            />
                                                        }
                                                        label={
                                                            <p style={{ fontSize: 14, fontWeight: "bold", lineHeight: "20px" }}>
                                                                (필수) 소개서,뉴스레터 등 정보수신에 동의합니다.
                                                            </p>
                                                        }
                                                        labelPlacement="end"
                                                    />
                                                </div>
                                            );
                                        }}
                                    />
                                    <Controller
                                        control={control}
                                        name="checkbox2"
                                        render={(fields) => {
                                            const { value, onChange } = fields.field;

                                            return (
                                                <div style={{ display: "flex", color: "#8e8ea9", marginLeft: 10 }}>
                                                    <FormControlLabel
                                                        value="end"
                                                        control={
                                                            <Checkbox
                                                                disabled={resultAction.isLoading}
                                                                sx={{
                                                                    color: errors.checkbox2 ? "red" : ""
                                                                }}
                                                                onChange={onChange}
                                                                checked={value}
                                                            />
                                                        }
                                                        label={
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    fontSize: 14,
                                                                    fontWeight: "bold"
                                                                }}
                                                            >
                                                                필수
                                                                <a
                                                                    href="#"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        setTermsModalOpen(true);
                                                                    }}
                                                                    style={{
                                                                        textDecoration: "underline",
                                                                        marginLeft: 4,
                                                                        color: "#000",
                                                                        cursor: "pointer"
                                                                    }}
                                                                >
                                                                    개인정보 수집 및 이용
                                                                </a>
                                                                에 동의합니다.
                                                            </div>
                                                        }
                                                        labelPlacement="end"
                                                    />
                                                </div>
                                            );
                                        }}
                                    />
                                </div>
                                <button
                                    style={{
                                        backgroundColor: "#1f90ff",
                                        color: "white",
                                        height: 55,
                                        fontWeight: "500",
                                        borderRadius: "6px",
                                        textAlign: "center",
                                        cursor: "pointer"
                                    }}
                                    disabled={resultAction.isLoading}
                                    onClick={showConfirmModal}
                                >
                                    {resultAction.isLoading ? (
                                        <CircularProgress color="inherit" style={{ height: 24, width: 24 }} />
                                    ) : (
                                        "신청하기"
                                    )}
                                </button>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>

            {/* MODALS  */}
            <CustomModal
                open={alertModalOpen}
                onClose={() => {
                    setAlertModalOpen(false);
                }}
                width={{ xs: "85%", sm: "50%", md: 319 }}
                sx={{ padding: { md: "40px", xs: "20px" } }}
            >
                <Typography variant={"h2"} sx={{ marginTop: "20px", fontWeight: "bold", textAlign: "center", color: "#000000" }}>
                    신청 완료
                </Typography>
                <Typography variant={"h4"} sx={{ margin: "30px 0", textAlign: "center" }}>
                    {alertText}
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <AnimateButton>
                        <ProjectButton
                            onClick={() => {
                                setAlertModalOpen(false);
                            }}
                            text={"확인"}
                            sx={{
                                width: "100px",
                                borderRadius: "5px",
                                height: "40px"
                            }}
                        />
                    </AnimateButton>
                </Box>
            </CustomModal>

            <CustomModal
                open={confirmModalOpen}
                onClose={() => {
                    setConfirmModalOpen(false);
                }}
                width={{ xs: "85%", sm: "50%", md: 319 }}
                sx={{ padding: { md: "40px", xs: "20px" } }}
            >
                <Typography variant={"h2"} sx={{ marginTop: "20px", fontWeight: "bold", textAlign: "center", color: "#000000" }}>
                    상담 신청 확인
                </Typography>
                <Typography variant={"h4"} sx={{ fontWeight: "400", margin: "30px 0", textAlign: "center" }}>
                    제출하신 정보로 상담을 신청하시겠습니까?
                </Typography>
                <Grid container>
                    <Grid item xs={12} display={"flex"} justifyContent={"center"} gap={"10px"}>
                        <CloseButton
                            onClick={() => {
                                setConfirmModalOpen(false);
                            }}
                            text={"취소"}
                            style={{
                                width: "100px",
                                borderRadius: "5px",
                                backgroundColor: "#F3F3F3",
                                borderColor: "#DDDDDD",
                                color: "#444444"
                            }}
                        />
                        <SaveButton
                            onClick={handleSubmit(submit)}
                            text={"신청"}
                            disabled={resultAction?.isLoading}
                            style={{ width: "100px", border: "none", borderRadius: "5px", backgroundColor: "#503795", color: "#FFFFFF" }}
                        />
                    </Grid>
                </Grid>
            </CustomModal>

            <CustomModal
                open={termsModalOpen}
                onClose={() => {
                    setTermsModalOpen(false);
                }}
                width={{ xs: "85%", sm: "80%", md: "80%" }}
                sx={{ padding: "40px" }}
            >
                <Typography variant={"h2"} sx={{ marginTop: "20px", fontWeight: "bold", textAlign: "center", color: "#000000" }}>
                    개인정보 수집 및 이용
                </Typography>
                <Typography variant={"h4"} sx={{ margin: "30px 0"}}>
                    {siteInfo?.content?.termsUse && parser(siteInfo?.content?.termsUse || "")}
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <AnimateButton>
                        <ProjectButton
                            onClick={() => {
                                setTermsModalOpen(false);
                            }}
                            text={"확인"}
                            sx={{
                                width: "100px",
                                borderRadius: "5px",
                                height: "40px"
                            }}
                        />
                    </AnimateButton>
                </Box>
            </CustomModal>
        </div>
    );
};
