import React, { lazy, useState } from "react";
import { Grid, Typography, Card, Button } from "@mui/material";
import ProjectTab from "./tab";
import ProjectMobileTab from "./mobileTab";
import Loadable from "components/Loadable";
import ProjectButton from "components/buttons/ProjectButton";
import { useGetProjectConceptsQuery, useGetProjectFormulationsQuery, useGetProjectPackagesQuery } from "api/project";
import { CustomModal } from "components/modal";

const ProjectList = Loadable(lazy(() => import("./list")));
const ProjectAbout = Loadable(lazy(() => import("./register/about")));
const ProjectMainIngredient = Loadable(lazy(() => import("./register/ingredients")));
const ProjectSecondaryIngredient = Loadable(lazy(() => import("./register/secondaryIngredients")));
const ProjectFormulation = Loadable(lazy(() => import("./register/formulation")));
const ProjectPackage = Loadable(lazy(() => import("./register/package")));
const ProjectConcept = Loadable(lazy(() => import("./register/concept")));
const ProjectDate = Loadable(lazy(() => import("./register/date")));
const ProjectDupList = Loadable(lazy(() => import("./dupList")));

const steps = ["기본정보", "주원료 선택", "부원료 선택", "제형 선택", "패키지 선택", "제품 컨셉", "납기일 및 예산"];

const ProjectRequest = () => {
    const { data: formulations, formulationLoading } = useGetProjectFormulationsQuery();
    const { data: packages, packagesLoading } = useGetProjectPackagesQuery();
    const { data: concepts, conceptsLoading } = useGetProjectConceptsQuery();

    const [dupModalOpen, setDupModalOpen] = useState(false);

    const handleDupModalClose = () => {
        setDupModalOpen(false);
    };

    const [step, setStep] = useState(null);
    const [type, setType] = useState("");
    const [project, setProject] = useState(null);

    const screenWidth = window.innerWidth;

    return (
        <div className="side_menu_container">
            {
                screenWidth > 600 ? (
                    <div className="left">
                        <div className="side_menu">
                            <Grid container flexDirection={"column"} sx={{ minWidth: "230px", minHeight: "698px" }}>
                                <p style={{ marginTop: "1px", fontWeight: "500", fontSize: "25px" }}>견적 요청하기</p>
                                {steps.map((item, i) => (
                                    <ProjectTab
                                        key={i}
                                        text={item}
                                        step={i + 1}
                                        active={step == i + 1}
                                        currStep={step}
                                        projectStep={project?.projectStep || 1}
                                        setStep={(e) => {
                                            if (project) {
                                                if (step <= project.projectStep) {
                                                    setStep(e);
                                                }
                                            }
                                        }}
                                    />
                                ))}
                                {/*<Card variant="outlined" sx={{mt:4, background: '#f8f9fa', p: 2, borderColor: '#f8f9fa', maxWidth: '230px'}}>
                                    <Typography variant={'name'}>
                                        <span style={{
                                            padding: '3px 9px',
                                            borderRadius: '50%',
                                            background: '#000',
                                            color: '#fff',
                                            marginRight: '5px',
                                            fontFamily: 'serif'
                                        }}>i</span>
                                        계정 설정
                                    </Typography>
                                    <Typography sx={{color: '#b9bfc2', mt:2}}>
                                        계정 설정계정 설정계정 설정계정
                                        설정계정 설정계정 설정계정
                                        설정계정 설정계정 설정계정 설정계정 설정
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        sx={{
                                            mt: 2,
                                            outline: 'none',
                                            boxShadow: 'none',
                                            width: '100%',
                                            padding: '5px',
                                            background : '#f8f9fa',
                                            color: '#cfd5db',
                                            '&:hover' : {background: '#f1f3f5', boxShadow: 'none'}
                                        }}>
                                        설정계정
                                    </Button>
                                    <Button
                                        variant="contained"
                                        sx={{
                                            outline: 'none',
                                            boxShadow: 'none',
                                            width: '100%',
                                            padding: '5px',
                                            background : '#f8f9fa',
                                            color: '#cfd5db',
                                            '&:hover' : {background: '#f2f3f5', boxShadow: 'none'}
                                        }}>
                                        설정계정
                                    </Button>
                                </Card>*/}
                            </Grid>
                        </div>
                    </div>
                ) : (
                    ''
                )
            }
            <div className="side_menu_detail">
                {screenWidth < 600 ? (
                    <Grid container flexDirection={"row"} sx={{width: '100%', position: 'fixed', paddingTop: '20px', zIndex: '99', left: '0', top: '73px', background: '#f8f9fa' }}>
                        <p style={{ marginTop: '5px', paddingLeft: '12px', fontWeight: "500", fontSize: "25px" }}>견적 요청하기</p>
                        <Grid
                            item
                            xs={12}
                            display={'flex'}
                            sx={{
                                overflowX: 'auto',
                                width:'100%',
                                background: '#fff',
                                marginTop: '12px',
                                marginLeft: '12px',
                                marginRight: '14px',
                                padding: '4px 14px',
                                boxShadow: '0px 0px 5px rgb(102 102 102 / 40%)',
                                borderRadius: '8px'
                            }}
                        >
                            {steps.map((item, i) => (
                                <ProjectMobileTab
                                    key={i}
                                    text={item}
                                    step={i + 1}
                                    active={step == i + 1}
                                    currStep={step}
                                    projectStep={project?.projectStep || 1}
                                    setStep={(e) => {
                                        if (project) {
                                            if (step <= project.projectStep) {
                                                setStep(e);
                                            }
                                        }
                                    }}
                                />
                            ))}
                        </Grid>
                    </Grid>
                ) : ''}
                {!step && (
                    <div style={{marginTop: screenWidth < 600 ? '114px' : ''}}>
                        <Card
                            sx={{
                                maxWidth: "100%",
                                border: "1px solid #503795",
                                borderRadius: "15px",
                                padding: { xs: "10px", md: "20px 30px" },
                                cursor: "pointer",
                                mb: 2
                            }}
                        >
                            <Grid container display={'flex'} alignItems={'center'}>
                                <Grid item xs={8} sx={{ maxWidth: "100%" }}>
                                    <h5 style={{ color: "#414141", fontWeight: "500" }}>견적 요청서 불러오기</h5>
                                    <p style={{ marginTop: "8px", fontSize: "12", color: "#c4c4c4" }}>
                                        임시 저장된 견적요청서나, 발송했던 견적요청서를 불러올 수 있습니다.
                                    </p>
                                </Grid>
                                <Grid item xs={4} display={"flex"} justifyContent={"end"}>
                                    <ProjectButton
                                        text={"불러오기"}
                                        width={"76px"}
                                        fontWeight={"bolder"}
                                        loading={false}
                                        onClick={() => {
                                            setDupModalOpen(true);
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Card>
                        <Card
                            sx={{
                                maxWidth: "100%",
                                border: "1px solid #257EFC",
                                borderRadius: "15px",
                                padding: { xs: "10px", md: "20px 30px" },
                                cursor: "pointer",
                                mb: 2
                            }}
                        >
                            <Grid container display={'flex'} alignItems={'center'}>
                                <Grid item xs={8}>
                                    <h5 style={{ color: "#414141", fontWeight: "500" }}>새로운 견적 요청서 신청</h5>
                                    <p style={{ marginTop: "8px", fontSize: "12", color: "#c4c4c4" }}>
                                        새로운 견적 요청서를 등록 및 신청합니다.
                                    </p>
                                </Grid>
                                <Grid item xs={4} display={"flex"} justifyContent={"end"}>
                                    <ProjectButton
                                        text={"새로등록"}
                                        loading={false}
                                        width={"76px"}
                                        fontWeight={"bolder"}
                                        bcolor={"#257EFC"}
                                        hcolor={"#2251fc"}
                                        onClick={() => {
                                            setStep(1);
                                            setProject(null);
                                            setType("N");
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Card>
                    </div>
                )}
                {!step && (
                    <ProjectList
                        setProject={(e) => {
                            setProject(e);
                            setStep(e.projectStep);
                        }}
                    />
                )}
                <div style={{marginTop: screenWidth < 600 ? !step ? '' : '114px' : ''}}>
                    {step === 1 && <ProjectAbout data={project} setStep={(e) => setStep(e)} type={type} setProject={(e) => setProject(e)} />}
                    {step === 2 && (
                        <ProjectMainIngredient data={project} setStep={(e) => setStep(e)} type={type} setProject={(e) => setProject(e)} />
                    )}
                    {step === 3 && (
                        <ProjectSecondaryIngredient data={project} setStep={(e) => setStep(e)} type={type} setProject={(e) => setProject(e)} />
                    )}
                    {step === 4 && (
                        <ProjectFormulation
                            data={project}
                            setStep={(e) => setStep(e)}
                            formulations={formulations}
                            isLoading={formulationLoading}
                            setProject={(e) => setProject(e)}
                        />
                    )}
                    {step === 5 && (
                        <ProjectPackage
                            data={project}
                            setStep={(e) => setStep(e)}
                            packages={packages}
                            isLoading={packagesLoading}
                            setProject={(e) => setProject(e)}
                        />
                    )}
                    {step === 6 && (
                        <ProjectConcept
                            data={project}
                            setStep={(e) => setStep(e)}
                            concepts={concepts}
                            isLoading={conceptsLoading}
                            setProject={(e) => setProject(e)}
                        />
                    )}
                    {step === 7 && (
                        <ProjectDate
                            data={project}
                            setStep={(e) => setStep(e)}
                            type={type}
                            setProject={(e) => setProject(e)}
                        />
                    )}
                </div>
            </div>

            <CustomModal
                open={dupModalOpen}
                onClose={handleDupModalClose}
                width={{ xs: "90%", sm: "65%", md: "85%" }}
                sx={{ padding: {md: "40px", xs: "10px"} }}
            >
                <ProjectDupList listModalClose={() => handleDupModalClose()} setStep={(e) => setStep(e)} setProject={(e) => setProject(e)}/>
            </CustomModal>
        </div>
    );
};
export default ProjectRequest;
