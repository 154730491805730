import React, { createContext, useContext, useState } from "react";

const OrderContext = createContext({ size: 5, page: 0, beginDt: "", endDt: "" });

export const OrderProvider = ({ children }) => {
    const [options, setOptions] = useState({ size: 5, page: 0, beginDt: "", endDt: "" });
    const [selectedRadio, setSelectedRadio] = useState("all");

    return <OrderContext.Provider value={{ options, setOptions, selectedRadio, setSelectedRadio }}>{children}</OrderContext.Provider>;
};

export const useOrderContext = () => useContext(OrderContext);

