import React, { useState } from "react";
import { FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput, Skeleton } from "@mui/material";
import { forwardRef, useContext } from "react";
import { ValidateContext } from "components/form/ValidateContext";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export const CustomFormInput = forwardRef(({ defaultValue, loading, ...props }, _) => {
    const { error } = useContext(ValidateContext);
    const [show, setShow] = useState(false);

    return loading ? (
        <Skeleton animation="wave" height={60} sx={{ borderRadius: "12px" }} />
    ) : (
        <>
            {props.labelname ? <InputLabel htmlFor={props.labelname}>{props.labelname}</InputLabel> : ""}
            <OutlinedInput
                id={props.name}
                className="qna-create-input"
                name={props.name}
                type={!show && props.eye ? "password" : "text"}
                placeholder={props?.placeholder}
                error={props?.error ?? Object.keys(error).includes(props.name)}
                defaultValue={defaultValue}
                maxLength="255"
                fullWidth={!props.minWidth}
                rows={props.rows}
                endAdornment={
                    props.eye && (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => {
                                    setShow((show) => !show);
                                }}
                                edge="end"
                            >
                                {!show && props.eye ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    )
                }
                {...props}
            />
            {props?.helpertext ?? Object.keys(error).includes(props.name) ? (
                <FormHelperText error id={props.name}>
                    {props?.helpertext ?? error[props.name]}
                </FormHelperText>
            ) : (
                ""
            )}
        </>
    );
});
