import React from "react";
import arrowImgSrc from "assets/images/icon/ico_arrow.png";
import introduction01Imgsrc from "assets/images/sub/introduction01.png";
import introduction02Imgsrc from "assets/images/sub/introduction02.png";
import introduction03Imgsrc from "assets/images/sub/introduction03.png";
import introduction04Imgsrc from "assets/images/sub/introduction04.png";
import introduction05Imgsrc from "assets/images/sub/introduction05.png";
import introduction06Imgsrc from "assets/images/sub/introduction06.png";
import introduction07Imgsrc from "assets/images/sub/introduction07.png";
import CottageOutlinedIcon from "@mui/icons-material/CottageOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

const Introduction = () => {
    const handleScrollClick = (idx) => {
        window.scrollTo({
            top: 200 + 600 * idx,
            behavior: "smooth"
        });
    };

    const sctollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    const cardList = [
        {
            title: `필메이커스 에디터로\n제안해 주세요`,
            desc: `필메이커스 에디터는 주원료부터 부원료 패키지까지\n편리하게 의뢰할 수 있는 플랫폼 입니다.`,
            imgSrc: introduction01Imgsrc
        },
        {
            title: `필메이커스 전문팀에서\n개발을 검토합니다!`,
            desc: `필메이커스의 전문 영양팀과 기획팀이\n성분과 디자인 등을 검토하여 연락드립니다.`,
            imgSrc: introduction02Imgsrc
        },
        {
            title: `배합이 확정되면 샘플이\n무료로 제공됩니다!`,
            desc: `정제와 성상, 분말, 맛 등을 고려할 수 있도록\n샘플을 무료로 제공해 드립니다!`,
            imgSrc: introduction03Imgsrc
        },
        {
            title: `품폭 제조 신고도\n빠르게!`,
            desc: `빠른 신제품 출시를 위해 공인검사와\n품목제조신고를 필메이커스에서\n빠르게 진행해 드립니다.`,
            imgSrc: introduction04Imgsrc,
            width: 170
        },
        {
            title: `디자인, 패키지, 상세페이지\n광고심의를 도와드립니다!`,
            desc: `출시 전 필요한 광고심의와 디자인 상세페이지까지\n광고심의를 모두 도와드립니다!`,
            imgSrc: introduction05Imgsrc,
            width: 180
        },
        {
            title: `부자재 입고와\n꼼꼼한 생산 검수까지!`,
            desc: `빠르게 제품 생산 후 검수까지\n필메이커스에서 완벽하게 도와드립니다.`,
            imgSrc: introduction06Imgsrc,
            width: 170
        },
        {
            title: `고객사에게,\n빠른 납품!`,
            desc: `생산된 제품을 고객사에게\n안전하게 납품해 드립니다.`,
            imgSrc: introduction07Imgsrc
        }
    ];
    const stepList = [
        {
            label: "건기식 에디터 신제품 제안"
        },
        {
            label: "제안 개발 검토"
        },
        {
            label: "배합 확정"
        },
        {
            label: "샘플 제조"
        },
        {
            label: "품목 신고"
        },
        {
            label: "광고 심의"
        },
        {
            label: "부자재 발주 및 입고"
        }
        // {
        //     label: "제품 생산 및 검수"
        // },
        // {
        //     label: "납품"
        // }
    ];

    return (
        <div className="introduction">
            <div className="fixedBtn" onClick={sctollToTop}>
                <img src={arrowImgSrc} alt="arrow_img_src" width="25px" />
            </div>
            <div className="container">
                <div className="title">필메이커스에서는 이렇게 제작돼요!</div>
                <div className="step">
                    <div className="sectionStep">
                        {stepList.map((item, index) => (
                            <li
                                key={index}
                                onClick={() => {
                                    handleScrollClick(index);
                                }}
                            >
                                <div className="circle">{index + 1}</div>
                                <p>{item?.label || ""}</p>
                            </li>
                        ))}
                    </div>
                </div>
                {cardList.map((el, index) => (
                    <section key={index}>
                        {index % 2 !== 0 ? (
                            <div className="sectionText">
                                <p>{`STEP${index + 1}`}</p>
                                <p>{el.title}</p>
                                <p>{el.desc}</p>
                            </div>
                        ) : (
                            <div className="sectionCard">
                                <div className="header">
                                    <CottageOutlinedIcon />
                                    <PersonOutlineOutlinedIcon />
                                </div>
                                <div className="content">
                                    <img src={el?.imgSrc} alt="introduction_img_src" style={{ width: el?.width ?? 200 }} />
                                </div>
                                <div className="footer">
                                    <CottageOutlinedIcon />
                                    <PersonOutlineOutlinedIcon />
                                    <SettingsOutlinedIcon />
                                </div>
                            </div>
                        )}

                        {index % 2 !== 0 ? (
                            <div className="sectionCard" > 
                                <div className="header">
                                    <CottageOutlinedIcon />
                                    <PersonOutlineOutlinedIcon />
                                </div>
                                <div className="content">
                                    <img src={el.imgSrc} alt="introduction_img_src" style={{ width: el?.width ?? 200 }} />
                                </div>
                                <div className="footer">
                                    <CottageOutlinedIcon />
                                    <PersonOutlineOutlinedIcon />
                                    <SettingsOutlinedIcon />
                                </div>
                            </div>
                        ) : (
                            <div className="sectionText">
                                <p>{`STEP${index + 1}`}</p>
                                <p>{el.title}</p>
                                <p>
                                    필메이커스 에디터는 주원료부터 부원료 패키지까지 <br /> 편리하게 의뢰할 수 있는 플랫폼 입니다.
                                </p>
                            </div>
                        )}
                    </section>
                ))}
            </div>
        </div>
    );
};

export default Introduction;
