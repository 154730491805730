import { createApi } from "@reduxjs/toolkit/query/react";
import { Api } from "../index";
import { URL } from "store/urls";

export const AuthApi = createApi({
    reducerPath: "AuthApi",
    baseQuery: Api.fetchBaseQuery,
    tagTypes: ["Auth"],
    endpoints: (build) => ({
        login: build.mutation({
            query: (data) => ({
                url: URL.LOGIN,
                method: "POST",
                body: data
            }),
            invalidatesTags: ["INFO"]
        }),
        snsLogin: build.mutation({
            query: (data) => ({
                url: URL.SNS_LOGIN,
                method: "POST",
                body: data
            }),
            invalidatesTags: ["INFO"]
        }),
        getUserInfo: build.query({
            query: () => `${URL.USER_INFO}`,
            providesTags: ["INFO"]
        }),
        updateUser: build.mutation({
            query: (body) => ({
                url: `${URL.USER_UPDATE}`,
                method: "POST",
                body: body
            }),
            invalidatesTags: ["INFO"]
        }),
        updateUserImages: build.mutation({
            query: (body) => ({
                url: `${URL.USER_UPDATE_IMAGES}`,
                method: "POST",
                body: body
            }),
            invalidatesTags: ["LIST"]
        }),
        createUser: build.mutation({
            query: (body) => ({
                url: `${URL.USER_CREATE}`,
                method: "POST",
                body: body
            }),
            invalidatesTags: ["INFO"]
        }),
        userNameCheck: build.mutation({
            query: (body) => ({
                url: `${URL.USER_NAME_CHECK}/` + body,
                method: "POST"
            }),
            invalidatesTags: ["INFO"]
        }),
        userVerifyTokenSend: build.mutation({
            query: (body) => ({
                url: `${URL.EMAIL_VERIFICATION_SEND}`,
                method: "POST",
                body: body
            }),
            invalidatesTags: ["INFO"]
        }),
        verifyToken: build.mutation({
            query: (body) => ({
                url: `${URL.EMAIL_VERIFY}`,
                method: "POST",
                body: body
            }),
            invalidatesTags: ["INFO"]
        }),
        forgotId: build.mutation({
            query: (body) => ({
                url: `${URL.FORGOT_ID}`,
                method: "POST",
                body: body
            }),
            invalidatesTags: ["INFO"]
        }),
        forgotPassword: build.mutation({
            query: (body) => ({
                url: `${URL.FORGOT_PASSWORD}`,
                method: "POST",
                body: body
            }),
            invalidatesTags: ["INFO"]
        }),
        getOrderProjects: build.query({
            query: (params) => `${URL.PROJECT_ORDER_LIST}?${params}`,
            providesTags: ["PROJECT_ORDER_LIST"]
        }),
    })
});

export const {
    useLoginMutation,
    useSnsLoginMutation,
    useGetUserInfoQuery,
    useUpdateUserMutation,
    useUpdateUserImagesMutation,
    useCreateUserMutation,
    useUserNameCheckMutation,
    useUserVerifyTokenSendMutation,
    useVerifyTokenMutation,
    useForgotIdMutation,
    useForgotPasswordMutation,
    useGetOrderProjectsQuery
} = AuthApi;
