// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].oneOf[5].use[2]!./fonts.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "utf-8";

.orderList_card {background: #fff;border: 1px solid #e0e1e2;box-shadow: 0px 0px 5px rgb(102 102 102 / 40%);border-radius: 8px;width: 100%;height: 100%;}
.orderList_card th {padding: 20px; font-size: 18px;}
.orderList_card td {padding: 20px;}
.orderList_card .bottomBorder {border-bottom: 1px solid #e0e1e2;}
.orderList_card .rightBorder {border-right: 1px solid #e0e1e2;}
`, "",{"version":3,"sources":["webpack://./src/assets/css/mypage.css"],"names":[],"mappings":"AAAA,gBAAgB;;AAGhB,iBAAiB,gBAAgB,CAAC,yBAAyB,CAAC,8CAA8C,CAAC,kBAAkB,CAAC,WAAW,CAAC,YAAY,CAAC;AACvJ,oBAAoB,aAAa,EAAE,eAAe,CAAC;AACnD,oBAAoB,aAAa,CAAC;AAClC,+BAA+B,gCAAgC,CAAC;AAChE,8BAA8B,+BAA+B,CAAC","sourcesContent":["@charset \"utf-8\";\n@import url(fonts.css);\n\n.orderList_card {background: #fff;border: 1px solid #e0e1e2;box-shadow: 0px 0px 5px rgb(102 102 102 / 40%);border-radius: 8px;width: 100%;height: 100%;}\n.orderList_card th {padding: 20px; font-size: 18px;}\n.orderList_card td {padding: 20px;}\n.orderList_card .bottomBorder {border-bottom: 1px solid #e0e1e2;}\n.orderList_card .rightBorder {border-right: 1px solid #e0e1e2;}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
