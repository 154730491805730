import { MainConfig, AuthConfig, CommunityConfig, ProjectConfig } from "views/pages";

import { CommonCodeFilterApi } from "api/commonCode";
import { BannerApi } from "api/main/banner";
import { UserAddressApi } from "api/userAddress/index";

export const Middlewares = [
    MainConfig.API.middleware,
    AuthConfig.API.middleware,
    CommunityConfig.API.middleware,
    ProjectConfig.API.middleware,
    CommonCodeFilterApi.middleware,
    BannerApi.middleware,
    UserAddressApi.middleware,
];
