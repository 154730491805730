import { Grid } from "@mui/material";
import storage01ImgSrc from "../../../assets/images/sub/storage01.png";
import storage02ImgSrc from "../../../assets/images/sub/storage02.png";
import storage03ImgSrc from "../../../assets/images/sub/storage03.png";
import storage04ImgSrc from "../../../assets/images/sub/storage04.png";
import storage05ImgSrc from "../../../assets/images/sub/storage05.png";
import storage06ImgSrc from "../../../assets/images/sub/storage06.png";

const Storage = () => {
    return (
        <div className="storage">
            {/* <div className="banner">
                <div className="container">
                    <p className="title">{`보관·배송도 걱정하지 마세요! \n 필메이커스 풀필먼트 서비스가 책임집니다.`}</p>
                    <p className="subTitle">
                        판매자 대신 주문을 받고 창고에서 제품을 꺼내서(Picking) 포장하고(Packing), 배송(Delivery)까지 해주는 서비스입니다.
                        고객이 원할 경우 반품(Refund) 및 교환(Exchange) 서비스까지 대신합니다.
                    </p>
                    <div className="image">
                        <img src={storage01ImgSrc} alt="storage_01" />
                    </div>
                </div>
            </div> */}
            <section className="brand01">
                <div className="container h_full">
                    <div className="inn">
                        <h2 className="brand_tit">{`보관·배송도 걱정하지 마세요! \n 필메이커스 풀필먼트 서비스가 책임집니다.`}</h2>
                        <p className="brand_desc">{` 판매자 대신 주문을 받고 창고에서 제품을 꺼내서(Picking) 포장하고(Packing), 배송(Delivery)까지 해주는 서비스입니다.
                        고객이 원할 경우 반품(Refund) 및 교환(Exchange) 서비스까지 대신합니다.`}</p>
                        <div className="img">
                            <img src={storage01ImgSrc} alt="필메이커스 풀필먼트 서비스" />
                        </div>
                    </div>
                </div>
            </section>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <div className="steps">
                    <p className="title">{`필메이커스 풀필먼트 서비스`}</p>
                    <div className="divider" />
                    <div className="stepItem">
                        <div className="imageContainer">
                            <img className="image" src={storage02ImgSrc} alt="storage_img_src" />
                        </div>
                        <div className="dot">
                            <div />
                        </div>
                        <div className="text">
                            <p className="steptitle">Step 1</p>
                            <p className="stepdesc">주문을 받습니다.</p>
                        </div>
                    </div>
                    {/* <Grid container>
                        <Grid item xs={12} md={4}>
                            <div className="stepItem">
                                <Grid item xs={4} md={4}>
                                className="imageContainer" <div>
                                </Grid>
                                </div>    
                                <img className="image" src={storage03ImgSrc} alt="storage_img_src" />
                                <Grid item xs={4} md={4}>
                                    <div className="dot">
                                        <div />
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    <div className="">
                                        <p className="steptitle">Step 2</p>
                                        <p className="stepdesc">지역별 물류 센터에 전달됩니다.</p>
                                    </div>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid> */}
                    <div className="stepItem">
                        <div className="imageContainer">
                            <div className="imageContainer">
                                <img className="image" src={storage03ImgSrc} alt="storage_img_src" />
                            </div>
                        </div>
                        <div className="dot">
                            <div />
                        </div>
                        <div className="text">
                            <p className="steptitle">Step 2</p>
                            <p className="stepdesc">지역별 물류 센터에 전달됩니다.</p>
                        </div>
                    </div>
                    <div className="stepItem">
                        <div className="imageContainer">
                            <img className="image" src={storage04ImgSrc} alt="storage_img_src" />
                        </div>
                        <div className="dot">
                            <div />
                        </div>
                        <div className="text">
                            <p className="steptitle">Step 3</p>
                            <p className="stepdesc">상품별 맞춤 서비스로 안전하게 포장합니다.</p>
                        </div>
                    </div>
                    <div className="stepItem">
                        <div className="imageContainer">
                            <img className="image" src={storage05ImgSrc} alt="storage_img_src" />
                        </div>
                        <div className="dot">
                            <div />
                        </div>
                        <div className="text">
                            <p className="steptitle">Step 4</p>
                            <p className="stepdesc">다양한 방법으로 소비자에게 신속하게 전달합니다.</p>
                        </div>
                    </div>
                    <div className="stepItem">
                        <div className="imageContainer">
                            <img className="image" src={storage06ImgSrc} alt="storage_img_src" />
                        </div>
                        <div className="dot">
                            <div />
                        </div>
                        <div className="text">
                            <p className="steptitle">Step 5</p>
                            <p className="stepdesc">반품과 교환까지 친절하게 책임집니다.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Storage;
