import React, { lazy } from "react";
import Loadable from "components/Loadable";
import NotFound from "components/notFound";
import Pages from "store/pages";
import { AuthConfig } from "views/index";
import CommunityConfig from "views/pages/community";
import FAQ from "views/pages/mypage/faq";
import Introduction from "views/pages/static/introduction";
import Pillmakers from "views/pages/static/pillmakers";
import Storage from "views/pages/static/storage";

const Main = Loadable(lazy(() => import("views/pages/main/main")));
const DynamicPage = Loadable(lazy(() => import("views/pages/dynamic/page")));

const MainRoutes = [
    // MAIN
    { path: Pages.MAIN, element: <Main /> },

    // AUTH
    { path: "", children: [...AuthConfig.routes] },

    // COSTUMER SERVICE
    { path: "", children: [...CommunityConfig.routes] },
    { path: "/web/contents/:id", element: <DynamicPage /> },

    // static pages
    { path: "/pillmakers", element: <Pillmakers /> },
    { path: "/introduction", element: <Introduction /> },
    { path: "/service/introduction", element: <Storage /> },

    // 404 Not Found 페이지
    { path: "*", element: <NotFound /> }
];

export default MainRoutes;

