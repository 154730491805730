export const URL = {
    LOGIN: "/web/login",
    MENU_LIST: "/web/menu/list",
    SITE_INFO: "/web/site/info",
    SITE_SETTINGS_INFO: "/web/site/settings/info",
    USER_INFO: "/web/user/info",
    USER_UPDATE: "/web/user/update",
    USER_CREATE: "/web/user/create",
    USER_NAME_CHECK: "/web/user/userIdExist",
    EMAIL_VERIFICATION_SEND: "/web/email/verification/send",
    EMAIL_VERIFY: "/web/email/auth/verify",
    FORGOT_ID: "/web/forgot/id",
    FORGOT_PASSWORD: "/web/forgot/password",

    PROJECT_LIST: "/web/project/list",
    PROJECT_DETAIL: "/web/project",
    PROJECT_CREATE: "/web/project/create",
    PROJECT_UPDATE: "/web/project/update",
    PROJECT_DUPLICATE: "/web/project/duplicate",
    PROJECT_DELETE: '/web/project/delete',

    //PROJECT ORDER
    PROJECT_ORDER_LIST: "/web/project/order/list",

    USER_ADDRESS_LIST: "/web/user/address/list",
    USER_ADDRESS_CREATE: "/web/user/address/create",
    USER_ADDRESS_UPDATE: "/web/user/address/update",
    USER_ADDRESS_DELETE: '/web/user/address/delete',

    PROJECT_INGREDIENTS_REGISTER: "/web/project/ingredient/register",
    PROJECT_SECONDARY_INGREDIENTS_REGISTER: "/web/project/secondary/ingredient/register",

    INGREDIENT_MAIN_LIST: '/web/main/ingredient/list',
    INGREDIENT_SECONDARY_LIST: '/web/secondary/ingredient/list',

    PILL_TYPE: '/web/pill/type/detail',
    CONCEPT_LIST: '/web/project/concepts',
    PACKAGE_LIST: '/web/project/packages',
    FORMULATION_LIST: '/web/project/formulations',

    COMMONCODE_FILTER_LIST: "/web/codes/group",

    // NOTICE
    NOTICE_LIST: "/web/notice/list",
    NOTICE_DETAIL: "/web/notice/detail",

    NEWS_LIST: "/web/news/list",
    NEWS_DETAIL: "/web/news/detail",

    EVENT_LIST: "/web/event/list",
    EVENT_DETAIL: "/web/event/detail",

    QNA_LIST: "/web/qna/list",
    QNA_DETAIL: "/web/qna/detail",
    QNA_CREATE: "/web/qna/create",
    QNA_UPDATE: "/web/qna/update",
    QNA_DELETE: "/web/qna/delete",

    CONTACT: "/web/contact/create",
    CONTACT_LIST: "/web/contact/list",
    CONTACT_DETAIL: "/web/contact/detail",

    INQUIRY_CREATE: "/web/inquiry/create",

    GALLERY_LIST: "/web/gallery/list",
    GALLERY_DETAIL: "/web/gallery/detail",

    FAQ_LIST: "/web/faq/list",
    DYNAMIC_PAGE: "/web/menu/contents",

    REVIEW_LIST: "/web/review/list",
    REVIEW_DETAIL: "/web/review/detail",
    REVIEW_CREATE: "/web/review/create",

    STAFF_LIST: "/web/staff/list",
    STAFF_CEO: "/web/staff/detail/ceo",
    STAFF_CTO: "/web/staff/detail/cto",
    STAFF_VICE: "/web/staff/detail/president",
    STAFF_VICE_TWO: "/web/staff/detail/presidenttwo",
    STAFF_DETAIL: "/web/staff/detail",
    STAFF_CREATE: "/web/staff/create",

    CERT_LIST: "/web/certificate/list",

    PORTFOLIO_LIST: "/web/portfolio/list",
    PORTFOLIO_DETAIL: "/web/portfolio/detail",
    YOUTUBE_LIST: "/web/youtube/list",

    SOLUTION_SMS: "/web/solution/detail/sms",
    SOLUTION_KAKAO: "/web/solution/detail/kakao",
    SOLUTION_RCS: "/web/solution/detail/rcs",
    SOLUTION_OUTSOURCING: "/web/solution/detail/outsourcing",

    //MAIN
    MAIN_INFO: "/web/main",
    CURRENT_TIME: "/web/current/time"

    //BANNER
    // BANNER_LIST: '/web/main'
};
