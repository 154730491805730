export const ButtonStyle = {
    mainButton: {
        background: "#503795",
        color: "#ffff"
    },
    cusBlackButton: {
        borderRadius: "25px",
        color: "#767676",
        fontSize: "18px",
        fontWeight: "500",
        width: "100px",
        border: "2px solid #767676",
        background: "#fefefe",
        minWidth: "200px",
        "&:hover": {
            background: "#585858",
            color: "#fff",
            borderColor: "#585858"
        }
    },
    cusGrayOutline: {
        borderRadius: "25px",
        background: "#ffffff",
        color: "#585858",
        width: "250px",
        height: "50px",
        borderColor: "#585858",
        fontSize: "clamp(10px, 0.59vw + 8.52px, 18px)",
        fontWeight: 500,
        marginLeft: "auto",
        marginRight: "auto",
        "&:hover": {
            background: "#585858",
            color: "#fff",
            borderColor: "#585858"
        }
    },
    cusBlueButton: {
        borderRadius: "0",
        background: "#2196F3",
        color: "#fff",
        width: "100px",
        borderColor: "#2196F3",
        float: "right",
        "&:hover": {
            background: "#2196F3",
            color: "#fff",
            borderColor: "#2196F3"
        }
    },
    saveBtn: {
        borderRadius: "0",
        background: "#333333",
        color: "#fff",
        borderColor: "#333333",
        width: "100px",
        "&:hover": {
            background: "#333333",
            color: "#fff",
            borderColor: "#333333"
        }
    },
    closeBtn: {
        backgroundColor: "rgb(243, 243, 243)",
        borderColor: "rgb(221, 221, 221)",
        color: "rgb(68, 68, 68)",
        "&:hover": {
            background: "#fff",
            color: "#000",
            borderColor: "rgba(51,51,51,0.72)"
        }
    },
    backBtn: {
        borderRadius: "0",
        background: "#fff",
        color: "#000",
        borderColor: "rgba(51,51,51,0.72)",
        width: "200px",
        "&:hover": {
            background: "#fff",
            color: "#000",
            borderColor: "rgba(51,51,51,0.72)"
        }
    },
    fileUploadBtn: {
        color: "#00C3F9",
        padding: "5px 20px",
        borderColor: "#00C3F9",
        "&:hover": {
            background: "#00C3F9",
            color: "#fff",
            borderColor: "#00C3F9"
        }
    },
    searchButton: {
        borderRadius: "0",
        background: "#e3e3e3",
        color: "#fff",
        width: "25px",
        borderColor: "rgb(239, 240, 240)",
        "&:hover": {
            background: "rgb(239, 240, 240)",
            color: "#fff",
            borderColor: "rgb(239, 240, 240)"
        }
    }
};
