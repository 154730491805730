/**
 * COMMON FUNCTIONS
 */
import receipt from "assets/images/icon/1._receipt.png";
import estimate from "assets/images/icon/2._estimate.png";
import contract from "assets/images/icon/3._contract.png";
import progressing from "assets/images/icon/4._progressing.png";
import complete from "assets/images/icon/5._complete.png";

var loggedInUser = "";
export var userToken = ""; //  token for logged in user

//  setting loggedin user
export function setLoggedInUser(userData) {
    loggedInUser = userData;
}

//  setting userToken
export function setUserToken(newToken) {
    userToken = newToken;
}

export function getLoggedInUser() {
    return loggedInUser;
}

//  getting translated text
export function getTranslatedText(text) {
    return text;
}

export function getCurrentUrl() {
    return window.location.pathname;
}

//@author: Dral
//get corresponding name
export function findCorName(id, column, list, corr) {
    if (list && list.length > 0) {
        const item = list.find((item) => {
            if (corr) {
                return id == item[corr];
            }
            return id == item.code;
        });
        return item ? item[column] : "no item";
    }
    return "";
}

//@author: Dral
//get calculated seq
export const calcSeq = (data, index) => {
    if (data?.totalElements) {
        return +data?.totalElements - +data?.number - index;
    }
    return 0;
};

export function addComma(n) {
    var reg = /(^[+-]?\d+)(\d{3})/; // 정규식
    n += ""; // 숫자를 문자열로 변환

    while (reg.test(n)) n = n.replace(reg, "$1" + "," + "$2");

    return n;
}

export function removeComma(str) {
    var reg = /[,]/g;
    var val = str.replace(reg, "");

    if (isNaN(val)) return str;
    else return parseInt(val, 10);
}

export function parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join('')
    );

    return JSON.parse(jsonPayload);
};
